import React from "react";
import Layout from "../components/layout/index";
import HeroImageSection from "../components/contact/HeroImageSection";
// import ContactSidebar from "../components/contact/contactsSidebar"
// import ContactCards from "../components/contact/contactsCards"
import QuickContact from "../components/contact/quickContact";
import { useTheme } from "@material-ui/core/styles";
import { Grid, useMediaQuery } from "@material-ui/core";
// import CustomButton from "../components/common/button"
import { graphql } from "gatsby";
export default function Contact({ data }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const seoData = {
    title: data?.strapiContactsPage?.seo_title,
    description: data?.strapiContactsPage?.seo_description,
    image: data?.strapiContactMetaData?.image?.publicURL,
    keyword: data?.strapiContactsPage?.seo_tags,
    url: "/contact",
  };

  return (
    <Layout seoData={seoData}>
      {/* <Header /> */}
      <Grid container justify="center" style={{ backgroundColor: "#FBFBFB" }}>
        <Grid item xs={12}>
          <HeroImageSection data={data?.strapiContactsPage} />
        </Grid>
        {/* <Grid item xs={10} style={{ marginTop: "3%" }}>
          <ContactCards data={data?.strapiContactsPage} />
        </Grid> */}

        <Grid container style={{ padding: isMobile ? "0px 5px" : "20px 30px" }}>
          <Grid item xs={12} sm={12} md={8}>
            <QuickContact />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={4}>
            <ContactSidebar />
          </Grid> */}
        </Grid>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  {
    strapiContactsPage {
      title
      contactNo
      address
      email
      seo_title
      seo_tags
      seo_description
      heroImage {
        url
      }
    }
  }
`;
