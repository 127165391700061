import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Grid, Typography, TextField, useMediaQuery } from "@material-ui/core"
import CustomButton from "../common/button"
import { FormHelperText } from "@material-ui/core"
import { SnackbarProvider, useSnackbar } from "notistack"
import { STRAPI_API_URL } from "../../utils/constants"
function MyApp () {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [name, setName] = useState("")
  const [subject, setSubject] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [subjectError, setSubjectError] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [messageError, setMessageError] = useState(null)

  async function subscribe (contact) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(contact),
    }
    await fetch(
      // `${process.env.API_URL}/subscribers`,
      `${STRAPI_API_URL}contacts`,

      requestOptions
    )
      .then(response => response.json())
      .then(response => {
        if (response?.error)
        {
          enqueueSnackbar("An Error Occurred!", { variant: "error" })
        } else
        {
          enqueueSnackbar("Saved Successfully!", { variant: "success" })
        }
      })
  }

  const handleContact = () => {
    const contact = { name, subject, email, message }

    if (name === "" || subject === "" || message === "" || email === "")
    {
      if (name === "")
      {
        setNameError("Enter Name")
      }
      if (subject === "")
      {
        setSubjectError("Enter Subject")
      }
      if (message === "")
      {
        setMessageError("Enter Message")
      }

      if (email === "")
      {
        setEmailError("Enter Email Address")
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))
    {
      setEmailError("Enter Valid Email Address")
    } else
    {
      setEmailError(null)
      setNameError(null)
      setSubjectError(null)
      setMessageError(null)
      subscribe(contact)
    }
  }
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          className={classes.heading}
          style={{ fontSize: isMobile && "24px" }}
        >
          Quick Contact
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <TextField
          id="name"
          classes={{ root: classes.root }}
          value={name}
          style={{ width: "100%" }}
          placeholder="Name"
          name="name"
          type="text"
          onChange={e => {
            setNameError(null)
            setName(e.target.value)
          }}
          error={nameError ? true : false}
        />
        <FormHelperText
          style={{ color: "red", marginLeft: "25%", marginBottom: "5%" }}
        >
          {nameError}
        </FormHelperText>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="email"
          value={email}
          classes={{ root: classes.root }}
          style={{ width: "100%" }}
          placeholder="Email"
          name="email"
          type="text"
          onChange={e => {
            setEmailError(null)
            setEmail(e.target.value)
          }}
          error={emailError ? true : false}
        />
        <FormHelperText
          style={{ color: "red", marginLeft: "25%", marginBottom: "5%" }}
        >
          {emailError}
        </FormHelperText>
      </Grid>

      <Grid item xs={8}>
        <TextField
          id="subject"
          value={subject}
          classes={{ root: classes.root }}
          style={{ width: "100%" }}
          placeholder="Subject"
          name="subject"
          type="text"
          onChange={e => {
            setSubjectError(null)
            setSubject(e.target.value)
          }}
          error={subjectError ? true : false}
        />
        <FormHelperText
          style={{ color: "red", marginLeft: "25%", marginBottom: "5%" }}
        >
          {subjectError}
        </FormHelperText>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="message"
          value={message}
          classes={{ root: classes.messageRoot }}
          // label="Message"
          style={{ width: "100%" }}
          placeholder="Message"
          name="message"
          type="text"
          multiline
          rows={7}
          variant="outlined"
          onChange={e => {
            setMessageError(null)
            setMessage(e.target.value)
          }}
          error={messageError ? true : false}
        />
        <FormHelperText
          style={{ color: "red", marginLeft: "25%", marginBottom: "5%" }}
        >
          {messageError}
        </FormHelperText>
      </Grid>
      <Grid container>
        <Grid item xs={2} style={{ marginLeft: "17%", marginBottom: '2%' }}>
          <CustomButton btnText="Send" height="56px" onClick={handleContact} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function QuickContactSection () {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  )
}

const useStyles = makeStyles(theme => ({
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "42.19px",
    color: "#000000",
    marginLeft: "16%",
    marginBottom: "3%",
  },
  root: {
    marginLeft: "25%",
    // marginBottom: "5%",
    "& .MuiInputBase-root": {
    color: "#3F3F3F",
      
      // color: "#000000",
      borderBottom: "1px solid #C4C4C4",
    },
    "& .MuiFormLabel-root": {
    color: "#3F3F3F",
      
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#F28705",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
  },
  messageRoot: {
    // borderBottom: "1px solid #C4C4C4",
    marginTop: "5%",
    marginLeft: "25%",
    "& .MuiInputBase-root": {
    color: "#3F3F3F",
      
      // color: "#000000",
      border: "1px solid #C4C4C4",
    },
    "& .MuiFormLabel-root": {
    color: "#3F3F3F",
      
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#F28705",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
  },
}))
